import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Div100vh from 'react-div-100vh';
import { RectShape } from 'react-placeholder/lib/placeholders';
import classNames from 'classnames';
import isMobile from 'connect/modules/utils/isMobile';
import { language, MenuItem } from '@platform/rcl';
import item from './item';
import styles from './styles.module.scss';

function SideMenu (props) {
  const { data, loading, menuVisible } = props;

  const [index, setIndex] = useState(0);
  const { top, bottom } = data;

  const classes = classNames(
    'sidebar',
    styles.sidebar,
    { open: menuVisible },
  );

  if (loading) {
    return (
      <Div100vh className={styles.sidebarBasic}>
        <RectShape color="#e0e0e0" style={{ width: '80px', height: '100%' }} />
      </Div100vh>
    );
  }

  return (
    <Div100vh className={classes} onMouseLeave={() => setIndex(0)}>
      <div className={styles.sidebarInner}>
        <div>
          {top.map((menuItem) => item(menuItem, index, setIndex))}
        </div>
        <div>
          { isMobile()
          && <MenuItem iconName="info-circle" className="open-intercom">{language('Help')}</MenuItem>}

          {bottom.map((menuItem) => item(menuItem, index, setIndex))}
        </div>
      </div>
    </Div100vh>
  );
}

SideMenu.propTypes = {
  /**
   * Data for the sidebar
   */
  data: PropTypes.object,
  /**
   * Whether the data for the sidebar is loading
   */
  loading: PropTypes.bool.isRequired,
  /**
   * Whether the menu is visible or not
   */
  menuVisible: PropTypes.bool.isRequired,
};

SideMenu.defaultProps = {
  data: {
    top: [],
    bottom: [],
  },
};

export default SideMenu;
