import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import promise from 'redux-promise-middleware';
import thunk from 'redux-thunk';
import { createBrowserHistory } from 'history';
import rootReducer from './reducers';

export const history = createBrowserHistory();

export const store = createStore(rootReducer, composeWithDevTools(
  applyMiddleware(
    promise,
    thunk,
  ),
));

export default store;
