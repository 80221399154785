import React from 'react';
import { Route } from 'react-router-dom';
import PublicRoute from 'connect/modules/auth/components/PublicRoute';

import FindOutMore from '../FindOutMore';

const routes = ([
  <PublicRoute exact path="/find-out-more" component={FindOutMore} />,
  <Route
    exact
    path="/pages/privacy"
    component={() => {
      window.location.href = 'https://www.bookinglive.com/privacy-policy';
      return null;
    }}
  />,
  <Route
    exact
    path="/pages/terms"
    component={() => {
      window.location.href = 'https://www.bookinglive.com/standard-terms';
      return null;
    }}
  />,
  <Route
    exact
    path="/pages/support"
    component={() => {
      window.location.href = 'https://intercom.help/bookinglive-cfd2a65046b3/en/articles/3947494-connecting-using-zoom-within-connect';
      return null;
    }}
  />,
  <Route
    exact
    path="/pages/documentation"
    component={() => {
      window.location.href = 'https://intercom.help/bookinglive-cfd2a65046b3/en';
      return null;
    }}
  />,
  <Route
    exact
    path="/pages/contact-us"
    component={() => {
      window.location.href = 'https://www.bookinglive.com/contact-us';
      return null;
    }}
  />,
]);

export default routes;
