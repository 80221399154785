function handleAccordionClick (index, activeIndex, setIndex) {
  let newIndex = -1;

  if (// handles click of 3 when 3:1 is active
    activeIndex.toString().indexOf(':') > -1
    && index.toString().indexOf(':') === -1
    && activeIndex.split(':')[0] === index
  ) {
    newIndex = -1;
  } else if (activeIndex !== index) {
    newIndex = index;
  } else if (index.toString().indexOf(':') > -1) {
    [newIndex] = index.split(':');
  }

  setIndex(newIndex);
}

export default handleAccordionClick;
