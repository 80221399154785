import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { getToken } from '@platform/auth/lib/selectors/auth';
import { ReduxContext } from '@platform/rcl';
import PublicLayout from 'connect/modules/admin/components/PublicLayout';

function PublicRoute (props) {
  const {
    component: Component, forceGuest, token, noRedirect, booking, ...rest
  } = props;

  if (forceGuest && token && !noRedirect) {
    return (
      <Route
        {...rest}
        render={() => (
          <Redirect to="/dashboard" />
        )}
      />
    );
  }

  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <PublicLayout booking={booking} {...matchProps}>
          <Component {...matchProps} token={token} />
        </PublicLayout>
      )}
    />
  );
}

PublicRoute.propTypes = {
  forceGuest: PropTypes.bool,
  /**
   * Indicates this page is part of the public booking process
   */
  booking: PropTypes.bool,
  /**
   * Don't automatically redirect this page
   */
  noRedirect: PropTypes.bool,
  /**
   * Component to be displayed for the rule
   */
  component: PropTypes.node.isRequired,
  /**
   * The security token for current user if logged in
   */
  token: PropTypes.string,
};

PublicRoute.defaultProps = {
  noRedirect: false,
  forceGuest: false,
  booking: false,
  token: null,
};

function mapStateToProps (state) {
  return { token: getToken(state) };
}

export default connect(
  mapStateToProps,
  null,
  null,
  {
    context: ReduxContext,
  },
)(PublicRoute);
