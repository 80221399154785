import { combineReducers } from 'redux';
import sourceReducer from './source';
import menuReducer from './menu';

const reducers = {
  source: sourceReducer,
  sidebar: menuReducer,
};

const rootReducer = combineReducers(reducers);
export default rootReducer;
