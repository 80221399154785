import * as R from 'ramda';
import { normalize, schema } from 'normalizr';

const defaultState = {
  data: {},
  entities: {},
  errors: {},
  loading: false,
};

// @todo move to schema folder?

// Define item schema
const pricePoint = new schema.Entity('pricePoints');
const upsell = new schema.Entity('upsells');
const scheduleElement = new schema.Entity('scheduleElements');
const scheduleGroup = new schema.Entity('scheduleGroups', {
  schedule_elements: [scheduleElement],
});
const staff = new schema.Entity('staff');

// Define item
const item = new schema.Entity('item', {
  price_points: [pricePoint],
  upsells: [upsell],
  schedule_groups: [scheduleGroup],
  staff: [staff],
}, {
  processStrategy: (entity) => R.omit([
    'created_at',
    'updated_at',
    'deleted_at',
    'account_id',
    'item_type',
    'event_type',
    'form_id',
    'interface',
    'entity',
    'flexi',
    'solo',
    'require_attendee_info',
  ], entity),
});

export default (state = defaultState, action = {}) => {
  switch (action.type) {
  case 'FETCH_ITEM_PENDING': {
    return {
      ...state,
      loading: true,
    };
  }

  // TODO: This needs to be tested
  case 'FETCH_ITEM_FULFILLED': {
    const { data } = action.payload.data;
    const { result, entities } = normalize(data, item);

    const getItem = R.pipe(
      R.prop(['item']),
      R.prop(result),
    );

    return {
      ...state,
      data: getItem(entities),
      entities: R.omit(['item'], entities),
      errors: {},
      loading: false,
    };
  }

  case 'FETCH_ITEM_REJECTED': {
    return {
      ...state,
      loading: false,
      errors: { global: action.payload.message },
    };
  }

  case 'FETCH_ITEMS_PENDING': {
    return {
      ...state,
      loading: true,
    };
  }

  case 'FETCH_ITEMS_FULFILLED': {
    return {
      ...state,
      list: action.payload.data.data,
      errors: {},
      loading: false,
    };
  }

  case 'FETCH_ITEMS_REJECTED': {
    return {
      ...state,
      loading: false,
      errors: { global: action.payload.message },
    };
  }

  default:
    return state;
  }
};
