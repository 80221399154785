import { combineReducers } from 'redux';

import itemReducer from './item';
import orderReducer from './order';
import purchaserReducer from './purchaser';
import attendeeReducer from './attendee';
import participantReducer from './particpant';

const reducers = {
  attendee: attendeeReducer,
  item: itemReducer,
  order: orderReducer,
  participant: participantReducer,
  purchaser: purchaserReducer,
};

const rootReducer = combineReducers(reducers);

export default rootReducer;
