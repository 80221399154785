import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import * as R from 'ramda';
import { Head, useSelector, useDispatch } from '@platform/rcl';
import { getUrlParameter } from 'connect/modules/utils/UrlParams';
import { fetchAuthLanguageStrings, fetchBookingLanguageStrings } from 'connect/modules/i18n/actions/language';
import { fetchAccountBySlug } from 'connect/modules/book/actions/account';
import { setSource } from '../../actions/source';

export function PublicLayout (props) {
  const {
    children,
    booking,
    match,
  } = props;
  const dispatch = useDispatch();

  const languageLoading = useSelector((state) => state.i18n.loading);

  useEffect(() => {
    window.scrollTo(0, 0);

    if (window.ym) {
      window.ym(52867015, 'hit', window.location.href);
    }

    const source = getUrlParameter(window.location.search, 'src');
    if (source) {
      dispatch(setSource(source.replace(/[^a-zA-Z0-9-_]/g, '')));
    }

    const companySlug = R.pathOr(false, ['params', 'company'], match);
    if (companySlug) {
      dispatch(fetchAccountBySlug(companySlug));
    }
  });

  useEffect(() => {
    if (booking) {
      const companySlug = match.params.company;
      dispatch(fetchBookingLanguageStrings(companySlug));
    } else {
      dispatch(fetchAuthLanguageStrings());
    }
  }, []);

  if (languageLoading === true) {
    return null;
  }

  return (
    <div className="public-layout">
      <Head>
        <title>BookingLive</title>
        <meta name="description" content="No.1 FREE Online Booking & Reservation System - Claim your FREE account TODAY FAST - EASY - FREE: Setup your free system in minutes and start selling online immediately. Save time, increase sales, improve customer experiences and grow your business today." />
      </Head>
      {children}
    </div>
  );
}

PublicLayout.propTypes = {
  /**
   * This component can have children
   */
  children: PropTypes.node,
  /**
   * Indicates this page is part of the public booking process
   */
  booking: PropTypes.bool,
  /**
   * React router history
   */
  match: ReactRouterPropTypes.match.isRequired,
};

PublicLayout.defaultProps = {
  children: null,
  booking: false,
};

export default PublicLayout;
