const defaultState = {
  list: [],
  errors: {},
  loading: false,
};

export default (state = defaultState, action = {}) => {
  switch (action.type) {
  case 'FETCH_ATTENDEES_PENDING': {
    return {
      ...state,
      loading: true,
    };
  }

  case 'FETCH_ATTENDEES_FULFILLED': {
    return {
      ...state,
      list: Object.values(action.payload.data.data),
      errors: {},
      loading: false,
    };
  }

  case 'FETCH_ATTENDEES_REJECTED': {
    return {
      ...state,
      loading: false,
      errors: { global: action.payload.message },
    };
  }

  default:
    return state;
  }
};
