/* eslint-disable no-console */
import moment from 'moment';
import backupLanguageStrings from '../english-strings';

const defaultState = {
  errors: {},
  loading: true,
  language: {},
};

export default (state = defaultState, action = {}) => {
  switch (action.type) {
  /**
     * Fetch Language Strings
     */
  case 'FETCH_LANGUAGE_STRINGS_PENDING': {
    return {
      ...state,
      loading: true,
    };
  }

  case 'FETCH_LANGUAGE_STRINGS_FULFILLED': {
    const language = action.payload.data?.meta?.locale || 'en-GB';
    moment.locale(language);

    return {
      ...state,
      language: action.payload.data.data,
      loading: false,
    };
  }

  case 'FETCH_LANGUAGE_STRINGS_REJECTED': {
    console.log('Error loading language strings', action.payload.message);
    console.log('Backup language strings loaded');

    return {
      ...state,
      loading: false,
      language: backupLanguageStrings.messages[''],
      // errors: { global: action.payload.message },
    };
  }

  /**
     * Fetch Auth Language Strings
     */
  case 'FETCH_AUTH_LANGUAGE_STRINGS_PENDING': {
    return {
      ...state,
      loading: true,
    };
  }

  case 'FETCH_AUTH_LANGUAGE_STRINGS_FULFILLED': {
    const language = action.payload.data?.meta?.locale || 'en-GB';
    moment.locale(language);

    return {
      ...state,
      language: action.payload.data.data,
      loading: false,
    };
  }

  case 'FETCH_AUTH_LANGUAGE_STRINGS_REJECTED': {
    console.log('Error loading language strings', action.payload.message);
    console.log('Backup language strings loaded');

    return {
      ...state,
      loading: false,
      language: backupLanguageStrings.messages[''],
      // errors: { global: action.payload.message },
    };
  }

  /**
     * Fetch Booking Language Strings
     */
  case 'FETCH_BOOKING_LANGUAGE_STRINGS_PENDING': {
    return {
      ...state,
      loading: true,
    };
  }

  case 'FETCH_BOOKING_LANGUAGE_STRINGS_FULFILLED': {
    const language = action.payload.data?.meta?.locale || 'en-GB';
    moment.locale(language);

    return {
      ...state,
      language: action.payload.data.data,
      loading: false,
    };
  }

  case 'FETCH_BOOKING_LANGUAGE_STRINGS_REJECTED': {
    console.log('Error loading language strings', action.payload.message);
    console.log('Backup language strings loaded');

    return {
      ...state,
      loading: false,
      language: backupLanguageStrings.messages[''],
      // errors: { global: action.payload.message },
    };
  }

  default:
    return state;
  }
};
