import http from 'connect/modules/http';

export function fetchLanguageStrings () {
  return (dispatch) => dispatch({
    type: 'FETCH_LANGUAGE_STRINGS',
    payload: http().get('language-strings'),
  });
}

export function fetchAuthLanguageStrings () {
  return (dispatch) => dispatch({
    type: 'FETCH_AUTH_LANGUAGE_STRINGS',
    payload: http().get('auth/language-strings'),
  });
}

export function fetchBookingLanguageStrings (accountSlug) {
  return (dispatch) => dispatch({
    type: 'FETCH_BOOKING_LANGUAGE_STRINGS',
    payload: http().get(`company/${accountSlug}/language-strings`),
  });
}
