const defaultState = {
  data: {
    top: [],
    bottom: [],
  },
};

export default (state = defaultState, action = {}) => {
  switch (action.type) {
  /**
   * Fetch sidebar
   */
  case 'FETCH_SIDEBAR_PENDING': {
    return {
      ...state,
      loading: true,
    };
  }

  case 'FETCH_SIDEBAR_FULFILLED': {
    return {
      ...state,
      loading: false,
      data: action.payload.data,
    };
  }

  case 'FETCH_SIDEBAR_REJECTED': {
    return {
      ...state,
    };
  }

  default:
    return state;
  }
};
