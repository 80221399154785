export const bottomMobileList = [
  {
    name: 'Home',
    icon: 'home',
    link: '/dashboard',
  },
  {
    name: 'Items',
    icon: 'box',
    link: '/item',
  },
  {
    name: 'Bookings',
    icon: 'ticket-alt',
    link: '/bookings',
  },
  {
    name: 'People',
    icon: 'users',
    link: '/customers',
  },
];
