import React from 'react';
import Div100vh from 'react-div-100vh';
import {
  RectShape,
} from 'react-placeholder/lib/placeholders';
import { Responsive, Container, Grid } from 'semantic-ui-react';
import { TopBar } from '@platform/rcl';
import KPIMetric from '@platform/dashboard/lib/components/KPIMetric';
import SideMenu from '../../SideMenu';
import styles from '../styles.module.scss';

function Placeholder () {
  return (
    <div>
      <Responsive>
        <Div100vh>

          <TopBar placeholder />
          <SideMenu loading />

          <div className={styles.content}>
            <Container>
              <Grid stackable>
                <Grid.Row columns={3} stretched>
                  <Grid.Column>
                    <KPIMetric
                      title=""
                      placeholder
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <KPIMetric
                      title=""
                      placeholder
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <KPIMetric
                      title=""
                      placeholder
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>

              <RectShape
                className="mt-2x"
                color="#e0e0e0"
                style={{ width: '100%', height: '400px' }}
              />
            </Container>
          </div>
        </Div100vh>

      </Responsive>
    </div>
  );
}

export default Placeholder;
