/**
 * Is this menu item currently active
 * @param activeIndex
 * @param index
 * @returns {boolean}
 */
export default function isAccordionActive (activeIndex, index) {
  if (activeIndex === index) {
    return true;
  }

  /**
   * Nested items use format 3:1 etc
   */
  return activeIndex.toString()
    .split(':')
    .includes(index.toString());
}
