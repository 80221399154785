export default (state = {}, action = {}) => {
  switch (action.type) {
  case 'FETCH_ACCOUNT_PENDING': {
    return { ...state, loading: true };
  }
  case 'FETCH_ACCOUNT_FULFILLED': {
    return { ...state, loading: false, account: action.payload.data.data };
  }
  case 'FETCH_FEATURE_FLAGS': {
    const hasAccount = state.account?.id;
    if (!hasAccount) {
      return { account: { permissions: { feature_flags: action.payload } } };
    }
    return state;
  }
  default:
    return state;
  }
};
