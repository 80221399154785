import * as R from 'ramda';

const defaultState = {
  purchaser: {},
  attendees: [],
  errors: {},
  loading: false,
};

export default (state = defaultState, action = {}) => {
  switch (action.type) {
  case 'RESET_BASKET': {
    return defaultState;
  }

  case 'SAVE_PARTICIPANTS_PENDING': {
    return {
      ...state,
      loading: true,
    };
  }

  case 'SAVE_PARTICIPANTS_FULFILLED': {
    return {
      ...state,
      loading: false,
      redirect: true,
    };
  }

  case 'SAVE_PARTICIPANTS_REJECTED': {
    return {
      ...state,
      loading: false,
      errors: {
        error: action?.payload?.response?.data?.error,
        errors: action?.payload?.response?.data?.errors,
      },
    };
  }

  case 'SELECT_PURCHASER': {
    return {
      ...state,
      purchaser: action.purchaser,
    };
  }

  case 'NEW_PURCHASER_PENDING': {
    return {
      ...state,
      loading: true,
    };
  }

  case 'NEW_PURCHASER_FULFILLED': {
    const purchaser = action.payload.data.data;
    // Handle new purchaser selected as attendee
    const attendees = state.attendees.map((attendee) => {
      if (attendee.id === 'PURCHASER') {
        // Set attendee id to purchaser id
        return {
          ...attendee,
          id: purchaser.id,
        };
      }
      return attendee;
    });
    return {
      ...state,
      purchaser,
      attendees,
      redirect: true,
      errors: {
        error: {},
        errors: {},
      },
    };
  }

  case 'NEW_PURCHASER_REJECTED': {
    return {
      ...state,
      loading: false,
      errors: {
        error: action?.payload?.response?.data?.error,
        errors: action?.payload?.response?.data?.errors,
      },
    };
  }

  case 'NEW_PURCHASER_PUBLIC_FULFILLED': {
    const { data } = action.payload.data;
    const { purchaser, attendees } = data;

    return {
      ...state,
      purchaser,
      attendees,
      publicRedirect: true,
      errors: {
        error: {},
        errors: {},
      },
    };
  }

  case 'NEW_PURCHASER_PUBLIC_REJECTED': {
    return {
      ...state,
      loading: false,
      publicRedirect: false,
      errors: {
        error: action?.payload?.response?.data?.error,
        errors: action?.payload?.response?.data?.errors,
      },
    };
  }

  case 'SAVE_PURCHASER_PENDING': {
    return {
      ...state,
      loading: true,
    };
  }

  case 'SAVE_PURCHASER_FULFILLED': {
    return {
      ...state,
      purchaser: action.payload.data.data,
    };
  }

  case 'SAVE_PURCHASER_REJECTED': {
    return {
      ...state,
      loading: false,
      redirect: false,
      errors: {
        error: action?.payload?.response?.data?.error,
        errors: action?.payload?.response?.data?.errors,
      },
    };
  }

  case 'NEW_ATTENDEE_PENDING': {
    return {
      ...state,
      loading: true,
    };
  }

  case 'NEW_ATTENDEE_FULFILLED': {
    const attendee = action.payload.data.data;
    const { pricePoint } = action.meta;
    attendee.pricePoint = pricePoint;
    return {
      ...state,
      attendees: R.append(attendee, state.attendees),
    };
  }

  case 'NEW_ATTENDEE_REJECTED': {
    return {
      ...state,
      loading: false,
      errors: {
        error: action?.payload?.response?.data?.error,
        errors: action?.payload?.response?.data?.errors,
      },
    };
  }

  case 'SAVE_ATTENDEE_PENDING': {
    return {
      ...state,
      loading: true,
    };
  }

  case 'SAVE_ATTENDEE_FULFILLED': {
    return {
      ...state,
      attendees: R.append(action.payload.data.data, state.attendees),
    };
  }

  case 'SAVE_ATTENDEE_REJECTED': {
    return {
      ...state,
      loading: false,
      errors: {
        error: action?.payload?.response?.data?.error,
        errors: action?.payload?.response?.data?.errors,
      },
    };
  }

  case 'SELECT_NEW_PURCHASER_AS_ATTENDEE': {
    // Strip attendees out of purchaser
    const { attendees, ...rest } = action.purchaser;

    return {
      ...state,
      attendees: R.append(rest, state.attendees),
    };
  }

  case 'SELECT_PURCHASER_AS_ATTENDEE': {
    // Strip attendees out of purchaser
    const { attendees, ...rest } = action.purchaser;

    return {
      ...state,
      attendees: R.append(rest, state.attendees),
    };
  }

  case 'SELECT_ATTENDEE': {
    return {
      ...state,
      attendees: R.append(action.attendee, state.attendees),
    };
  }

  default:
    return state;
  }
};
