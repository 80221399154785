import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { getToken } from '@platform/auth/lib/selectors/auth';
import { useSelector } from '@platform/rcl';
import AppLayout from 'connect/modules/admin/components/AppLayout';

export function AppRoute (props) {
  const { component: Component, ...rest } = props;

  const token = useSelector(getToken);

  if (token) {
    return (
      <Route
        {...rest}
        render={(matchProps) => (
          <AppLayout {...props}>
            <Component {...matchProps} />
          </AppLayout>
        )}
      />
    );
  }

  return (
    <Route
      {...rest}
      render={() => (
        <Redirect to="/login" />
      )}
    />
  );
}

AppRoute.propTypes = {
  /**
   * React component for the page
   */
  component: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.elementType,
  ]).isRequired,
};

export default AppRoute;
