/**
 *
 * @param locationSearchString from window.location.search e.g. ?param=value
 * @param paramName name of the parameter you want to retrieve
 * @returns {string}
 */
export function getUrlParameter (locationSearchString, paramName) {
  const name = paramName.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
  const regex = new RegExp(`[?&]${name}=([^&#]*)`);
  const results = regex.exec(locationSearchString);
  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
}
