import React from 'react';
import { MenuItem, MenuDropdown, language } from '@platform/rcl';
import isAccordionActive from './isAccordionActive';
import handleAccordionClick from './handleAccordionClick';

/**
 * Return a item for the menu
 * @param data
 * @param index - index of current menu item
 * @param setIndex - function to set new menu index
 * @param parent - the name of the parent dropdown (if possible)
 * @returns {JSX.Element|null}
 */
function item (data, index, setIndex, parent = '') {
  if (data.type === 'internal') {
    return (
      <MenuItem iconName={data.icon} link={data.link}>
        {language(data.name)}
      </MenuItem>
    );
  }

  if (data.type === 'dropdown') {
    return (
      <MenuDropdown
        menuName={language(data.name)}
        iconName={data.icon}
        active={isAccordionActive(index, data.name)}
        onClick={() => handleAccordionClick(parent ? `${parent}:${data.name}` : data.name, index, setIndex)}
        index={parent ? `${parent}:${data.name}` : data.name}
      >
        {data.link.map((menuItem) => item(menuItem, index, setIndex, data.name))}
      </MenuDropdown>
    );
  }

  return null;
}

export default item;
