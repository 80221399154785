import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import {
  setStore, ThemeProvider, defaultTheme, QueryClient, QueryClientProvider, ReduxContext,
  LazyTableProvider, LazyTableClient,
} from '@platform/rcl';
import { tokenVerifier } from '@platform/auth';
import App from './App';
import '../../sass/app.scss';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-table/react-table.css';
import 'react-placeholder/lib/reactPlaceholder.css';

import store from './store';

require('@platform/rcl/dist/global.css');

const queryClient = new QueryClient();
const lazyTableClient = new LazyTableClient();

tokenVerifier(store);
setStore(store);

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <BrowserRouter>
    <Provider store={store} context={ReduxContext}>
      <QueryClientProvider client={queryClient}>
        <LazyTableProvider client={lazyTableClient} contextSharing>
          <ThemeProvider theme={defaultTheme}>
            <Switch>
              <App />
            </Switch>
          </ThemeProvider>
        </LazyTableProvider>
      </QueryClientProvider>
    </Provider>
  </BrowserRouter>,
);

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.getRegistrations().then((registrations) => {
    // returns installed service workers
    if (registrations.length) {
      // eslint-disable-next-line no-restricted-syntax
      for (const registration of registrations) {
        registration.unregister();
      }
    }
  });

  navigator.serviceWorker.register('/service-worker.js');
}
