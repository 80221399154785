import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useChargebee } from '@platform/rcl';

function ChargebeeProvider ({ children }) {
  const [chargebeePortal, setChargebeePortal] = useState(null);
  const { CHARGEBEE_SITE: siteName } = window;
  const { chargebee } = useChargebee(siteName);

  function openChargebeePortal (options = {}) {
    chargebeePortal?.open(options);
  }

  useEffect(() => {
    if (chargebee) {
      const cbPortal = chargebee.createChargebeePortal();

      setChargebeePortal(cbPortal);
    }
  }, [chargebee]);

  return (
    <div>
      {React.cloneElement(children, { openChargebeePortal: () => openChargebeePortal() })}
    </div>
  );
}

ChargebeeProvider.propTypes = {
  /**
   * This component can have children
   */
  children: PropTypes.node,
};

ChargebeeProvider.defaultProps = {
  children: null,
};

export default ChargebeeProvider;
