import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { rootReducer as newBookReducer } from '@platform/admin-booking-process';
import { rootReducer as bookingProcess } from '@platform//booking-process';
import { rootReducer as authenticationReducer } from '@platform/auth';
import { rootReducer as pluginsReducer } from '@platform/plugins';
import { rootReducer as calendarReducer } from '@platform/admin-calendar';

import accountReducer from 'connect/modules/auth/reducers/account';
import i18nReducer from 'connect/modules/i18n/reducers/languages';
import bookReducer from 'connect/modules/book/reducers';
import crudReducer from 'connect/modules/crud/reducers/crud';
import adminReducer from 'connect/modules/admin/reducers';

const reducers = {
  account: accountReducer,
  auth: authenticationReducer,
  book: bookReducer,
  crud: crudReducer,
  form: formReducer,
  i18n: i18nReducer,
  admin: adminReducer,
  calendar: calendarReducer,
  plugins: pluginsReducer,
  newBook: newBookReducer,
  booking: bookingProcess,
};

const rootReducer = combineReducers(reducers);

export default rootReducer;
