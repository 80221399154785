const defaultState = {};

export default (state = defaultState, action = {}) => {
  switch (action.type) {
  case 'SET_SOURCE': {
    return {
      ...state,
      source: action.source,
    };
  }
  default:
    return state;
  }
};
