import React, { Suspense, cloneElement, useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import {
  ScrollToTop,
  TagManager,
  useDispatch,
  useQuery,
} from '@platform/rcl';
import auth from '@platform/auth';
import NotFoundPage from '@platform/auth/lib/pages/NotFoundPage';
import items from '@platform/items';
import dashboard from '@platform/dashboard';
import achievements from '@platform/achievements';
import adminBookingProcess from '@platform/admin-booking-process';
import profile from '@platform/profile';
import resources from '@platform/resources';
import systemSettings from '@platform/system-settings';
import plugins from '@platform/plugins';
import reports from '@platform/reports';
import order from '@platform/order';
import attendees from '@platform/attendees';
import customers from '@platform/customers';
import adminCalendar from '@platform/admin-calendar';

import routes from './config/routes';
import AppRoute from './modules/auth/components/AppRoute';
import PublicRoute from './modules/auth/components/PublicRoute';

function mapComponents (moduleIndex, moduleKey) {
  return (component, componentIndex) => cloneElement(
    component,
    {
      key: `${
        moduleIndex
      }-${
        componentIndex
      }-${
        moduleKey
      }`,
    },
  );
}

const privateModules = [
  resources,
  items,
  adminBookingProcess,
  profile,
  dashboard,
  systemSettings,
  achievements,
  plugins,
  adminCalendar,
  customers,
  attendees,
  order,
  reports,
];

const publicPrivateModules = [auth];

export function App () {
  const dispatch = useDispatch();

  const { data: featureFlagsData, isLoading: featureFlagsIsLoading } = useQuery('feature-flags', '/api/feature');

  useEffect(() => {
    if (!featureFlagsIsLoading) {
      dispatch({ type: 'FETCH_FEATURE_FLAGS', payload: featureFlagsData.data });
    }
  }, [featureFlagsIsLoading]);

  return (
    <div>
      <Suspense fallback={<div>loading</div>}>
        <ScrollToTop />
        <TagManager />
        <Switch>
          <Route exact path="/" render={() => (<Redirect to="/dashboard" />)} />
          {publicPrivateModules
            .map((module, moduleIndex) => module({ appRoute: AppRoute, publicRoute: PublicRoute })
              .map(mapComponents(moduleIndex, 'publicPrivateModules')))}
          {privateModules
            .map((module, moduleIndex) => module({ appRoute: AppRoute })
              .map(mapComponents(moduleIndex, 'privateModules')))}
          { routes }
          <AppRoute component={NotFoundPage} />
        </Switch>
      </Suspense>
    </div>
  );
}

export default App;
