const defaultState = {
  data: {},
  purchaser: {},
  attendees: [],
  errors: {},
  loading: false,
};

export default (state = defaultState, action = {}) => {
  switch (action.type) {
  case 'FETCH_ORDERS_FULFILLED': {
    return {
      ...state,
      list: Object.values(action.payload.data.data),
      loading: false,
      error: false,
    };
  }

  case 'FETCH_ORDERS_PENDING': {
    return {
      ...state,
      loading: true,
    };
  }

  case 'FETCH_ORDER_PENDING': {
    return {
      ...state,
      loading: true,
      error: false,
    };
  }

  case 'FETCH_ORDER_FULFILLED': {
    const { data } = action.payload.data;
    return {
      ...state,
      data,
      loading: false,
      error: false,
    };
  }

  case 'FETCH_ORDER_REJECTED': {
    return {
      ...state,
      loading: false,
      error: true,
      error_code: action.payload.response.status,
      error_message: action.payload.response.data.message,
    };
  }

  case 'NEW_ORDER_PENDING': {
    return {
      ...state,
      loading: true,
    };
  }

  case 'NEW_ORDER_FULFILLED': {
    return {
      ...state,
      data: action.payload.data.data,
      errors: {},
      loading: false,
    };
  }

  case 'NEW_ORDER_REJECTED': {
    return {
      ...state,
      loading: false,
      errors: { global: action.payload.message },
    };
  }

  case 'SAVE_ORDER_PENDING': {
    return {
      ...state,
      loading: true,
    };
  }

  case 'SAVE_ORDER_FULFILLED': {
    return {
      ...state,
      data: action.payload.data.data,
      errors: {},
      loading: false,
    };
  }

  case 'SAVE_ORDER_REJECTED': {
    return {
      ...state,
      loading: false,
      errors: {
        error: action?.payload?.response?.data?.error,
        errors: action?.payload?.response?.data?.errors,
      },
    };
  }

  case 'SAVE_ORDER_CUSTOM_FIELD_DATA_FULFILLED': {
    return {
      ...state,
      loading: false,
      success: true,
      errors: {},
    };
  }

  case 'SAVE_ORDER_CUSTOM_FIELD_DATA_REJECTED': {
    return {
      ...state,
      loading: false,
      success: false,
      errors: {
        error: action?.payload?.response?.data?.error,
        errors: action?.payload?.response?.data?.errors,
      },
    };
  }

  default:
    return state;
  }
};
