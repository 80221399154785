import * as R from 'ramda';

const defaultState = {
  entity: null,
  item: {},
  items: [],
  relatedItems: {},
  loading: false,
  errors: {},
};

/**
 * Merge state and mutations and return a new object.
 * Mutations overwrites state if they have the same key.
 * @param state
 * @param mutations
 * @returns {*}
 */
export function update (state, mutations) {
  return { ...state, ...mutations };
}

export default (state = defaultState, action = {}) => {
  switch (action.type) {
  case 'FETCH_CRUD_ITEMS_FULFILLED': {
    const { data } = action.payload;
    return {
      ...state,
      items: data,
      relatedItems: {},
      loading: false,
      errors: {},
    };
  }

  case 'FETCH_CRUD_ITEMS_PENDING': {
    return {
      ...state,
      loading: true,
      errors: {},
      items: {},
    };
  }

  case 'FETCH_CRUD_ITEMS_REJECTED': {
    return {
      ...state,
      loading: false,
      errors: { global: action.payload.message },
    };
  }

  case 'FETCH_CRUD_RELATED_ITEMS_FULFILLED': {
    const relatedItem = {};
    const { data } = action.payload.data;
    const { entity } = action.meta;
    const isPaginated = R.has('page_results');

    relatedItem[entity] = isPaginated(data)
      ? R.prop('page_results', data)
      : data;

    return {
      ...state,
      relatedItems: update(state.relatedItems, relatedItem),
      loading: false,
      errors: {},
    };
  }

  case 'FETCH_CRUD_RELATED_ITEMS_PENDING': {
    return {
      ...state,
      loading: true,
      errors: {},
    };
  }

  case 'FETCH_CRUD_RELATED_ITEMS_REJECTED': {
    return {
      ...state,
      loading: false,
      errors: { global: action.payload.message },
    };
  }

  case 'CLEAR_CRUD': {
    return defaultState;
  }

  case 'NEW_CRUD_ITEM': {
    return {
      ...state,
      item: { name: null },
    };
  }

  case 'SAVE_CRUD_ITEM_PENDING': {
    return {
      ...state,
      loading: true,
    };
  }

  case 'SAVE_CRUD_ITEM_FULFILLED': {
    return {
      ...state,
      items: [],
      errors: {},
      loading: false,
    };
  }

  case 'SAVE_CRUD_ITEM_REJECTED': {
    return {
      ...state,
      loading: false,
      errors: { global: action.payload.message },
    };
  }

  case 'FETCH_CRUD_ITEM_PENDING': {
    return {
      ...state,
      loading: true,
      item: {},
    };
  }

  case 'FETCH_CRUD_ITEM_FULFILLED': {
    const { data } = action.payload.data;
    const { entity } = data;

    return {
      ...state,
      entity,
      item: data,
      errors: {},
      loading: false,
    };
  }

  case 'UPDATE_CRUD_ITEM_PENDING': {
    return {
      ...state,
      loading: true,
    };
  }

  case 'UPDATE_CRUD_ITEM_FULFILLED': {
    const crudItem = typeof (action.payload.data.data) !== 'undefined'
      ? action.payload.data.data
      : action.payload.data;

    let { items } = state;

    if (typeof (state.items.data) !== 'undefined' && typeof (state.items.data.page_results) !== 'undefined') {
      items = state.items.data.page_results;
    } else if (typeof (state.items.data) !== 'undefined') {
      items = state.items.data;
    }

    return {
      ...state,
      items: items.map((item) => (item.id === crudItem.id ? item : crudItem)),
      crudItem,
      errors: {},
      loading: false,
    };
  }

  case 'UPDATE_CRUD_ITEM_REJECTED': {
    return {
      ...state,
      loading: false,
      errors: { global: action.payload.message },
    };
  }

  case 'DELETE_CRUD_ITEM_FULFILLED': {
    const id = action.payload;

    // TODO - Pagination needs to be handled nicely
    const items = (typeof (state.items.data) !== 'undefined' && typeof (state.items.data.page_results) !== 'undefined')
      ? state.items.data.page_results
      : state.items;

    return {
      ...state,
      items: items.filter((item) => item.id !== id),
    };
  }

  default:
    return state;
  }
};

export function getItems (state) {
  return state.crud.items.data;
}
