/* eslint-disable camelcase */
import React, {
  useEffect, useState,
} from 'react';
import qs from 'qs';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import {
  Responsive,
} from 'semantic-ui-react';
import { fetchLanguageStrings } from 'connect/modules/i18n/actions/language';
import {
  Head, Sidebar, MobileBottomNavigation, isBottomNavigationShown, TopBar, ReduxContext,
  useSelector, isAvalibleIntercomEmail,
} from '@platform/rcl';
// eslint-disable-next-line import/no-unresolved
import { fetchUser, fetchIntercom } from '@platform/auth/lib/actions/authentication';
// eslint-disable-next-line import/no-unresolved
import { getUser, getFullName, getIntercom } from '@platform/auth/lib/selectors/auth';
import isMobile from 'connect/modules/utils/isMobile';
import { fetchAccount } from 'connect/modules/auth/actions/account';
import { fetchSidebar } from '../../actions/menu';
import styles from './styles.module.scss';
import { bottomMobileList } from './config';
import ChargebeeProvider from './ChargebeeProvider';
import Placeholder from './Placeholder';

export function AppLayout ({
  user,
  intercom,
  fetchUser: fetchUserProp,
  fetchIntercom: fetchIntercomProp,
  fetchAccount: fetchAccountProp,
  fetchLanguageStrings: fetchLanguageStringsProp,
  fetchSidebar: fetchSidebarProp,
  children, fullname, languageLoading, location,
  sidebar,
}) {
  const [menuVisible, setMenuVisible] = useState(false);

  const account = useSelector((state) => state?.account?.account);

  /**
   * Take query string from slug and run Inline Manual walkthrough
   */
  function triggerInlineManualQS () {
    const tutorialID = qs.parse(location.search, { ignoreQueryPrefix: true }).topicId;
    const tutorialStep = qs.parse(location.search, { ignoreQueryPrefix: true }).topicStep;

    if (typeof (window.inline_manual_player) !== 'undefined') {
      if (tutorialStep && tutorialID) {
        window.inline_manual_player.activateStep(tutorialID, tutorialStep);
      } else if (tutorialID) {
        window.inline_manual_player.activateTopic(tutorialID);
      }
    }
  }

  /**
   * Load user information on mount
   */
  useEffect(() => {
    if (localStorage.token) {
      fetchLanguageStringsProp();
      fetchSidebarProp();

      if (!user) {
        fetchUserProp();
      }

      if (!intercom) {
        fetchIntercomProp();
      }

      fetchAccountProp();
    }
    return () => {
      window.Intercom('shutdown');
    };
  }, []);

  useEffect(() => {
    let timer;
    if (user && intercom && localStorage.token) {
      if (!isAvalibleIntercomEmail(user.contact.email)) return;

      const {
        company_name,
        company_sector,
        ...otherIntercom
      } = intercom;

      const intercomProps = {
        name: `${user.contact.firstname} ${user.contact.lastname}`,
        email: user.contact.email,
        company_name: user.contact.company,
        phone: user.contact.telephone,
        source: user.account.source,
        user_id: user.contact.email,
        ...otherIntercom,
        company: {
          id: user.account.id,
          name: company_name,
          industry: company_sector,
        },
      };

      window.Intercom('boot', {
        app_id: 'br7wvmjv',
        custom_launcher_selector: '.open-intercom',
        hide_default_launcher: isMobile(),
        ...intercomProps,
      });

      window.inlineManualTracking = {
        uid: user.id,
        name: `${user.contact.firstname} ${user.contact.lastname}`,
        first_name: user.contact.firstname,
        last_name: user.contact.lastname,
        ...intercomProps,
      };

      window.intercomSettings = {
        ...intercomProps,
      };

      if (window.inlineManualTracking) {
        let retries = 0;
        timer = setInterval(() => {
          if (typeof window.createInlineManualPlayer !== 'undefined') {
            if (typeof window.inline_manual_player === 'undefined') {
              window.createInlineManualPlayer(window.inlineManualPlayerData);
            }
            triggerInlineManualQS();
            clearInterval(timer);
          } else if (retries > 100) {
            clearInterval(timer);
          }
          retries += 1;
        }, 50);
      }
    } else {
      window.Intercom('shutdown');
    }
    // eslint-disable-next-line consistent-return
    return () => {
      clearInterval(timer);
    };
  }, [user, intercom]);

  useEffect(() => {
    if (user && isAvalibleIntercomEmail(user.contact.email)) {
      window.Intercom('update');
    }

    if (window.ym) {
      window.ym(52867015, 'hit', window.location.href);
    }

    if (menuVisible) {
      // This is safe, warning exists to prevent infinite loops.
      // eslint-disable-next-line react/no-did-update-set-state
      setMenuVisible(false);
    }

    if (typeof (window.inline_manual_player) !== 'undefined') {
      window.inline_manual_player.update();
    }
  }, [location]);

  /**
   * Toggle visibility for the SideMenu
   */
  function toggleMenu () {
    setMenuVisible((prevState) => !prevState);
  }

  const { pathname } = location;

  if (!account) {
    return (
      <Placeholder />
    );
  }

  if (languageLoading === true) {
    return (
      <Responsive>
        <Head>
          <title>BookingLive</title>
          <meta name="description" content="" />
        </Head>
        <ChargebeeProvider>
          <TopBar
            toggleMenu={() => toggleMenu()}
            name={fullname || ''}
            logoUrl="/images/logo.svg"
            placeholder={!fullname}
          />
        </ChargebeeProvider>
        <Sidebar
          menuVisible={menuVisible}
          data={sidebar}
        />
        {/* <ErrorMessage /> */}
        <div className={styles.content}>
          {children}
        </div>
        {isBottomNavigationShown(pathname) && (
          <Responsive maxWidth={1024}>
            <MobileBottomNavigation
              className={styles.bottomnav}
              navList={bottomMobileList}
              onCreate={() => {}}
            />
          </Responsive>
        )}
      </Responsive>
    );
  }

  return (
    <Responsive>
      <Head>
        <title>BookingLive</title>
        <meta name="description" content="" />
      </Head>
      <ChargebeeProvider>
        <TopBar
          toggleMenu={() => toggleMenu()}
          name={fullname || ''}
          logoUrl="/images/logo.svg"
        />
      </ChargebeeProvider>
      <Sidebar
        menuVisible={menuVisible}
        data={sidebar}
      />
      {/* <ErrorMessage /> */}
      <div className={styles.content}>
        {children}
      </div>
      {isBottomNavigationShown(pathname) && (
        <Responsive maxWidth={1024}>
          <MobileBottomNavigation
            className={styles.bottomnav}
            navList={bottomMobileList}
            onCreate={() => {}}
          />
        </Responsive>
      )}
    </Responsive>
  );
}

AppLayout.propTypes = {
  /**
   * React router location
   */
  location: ReactRouterPropTypes.location.isRequired,
  /**
   * This component can have children
   */
  children: PropTypes.node,
  /**
   * Full name of user
   */
  fullname: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]).isRequired,
  /**
   * Function to fetch user data
   */
  fetchUser: PropTypes.func,
  /**
   * Information about the current user
   */
  user: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.object,
  ]),
  /**
   * Information about user for intercom
   */
  intercom: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.object,
  ]),
  /**
   * Function to fetch the language strings
   */
  fetchLanguageStrings: PropTypes.func.isRequired,
  /**
   * Whether or not the language is loading
   */
  languageLoading: PropTypes.bool,
  /**
   * Function to fetch account data
   */
  fetchAccount: PropTypes.func,
  /**
   * Function to fetch intercom data
   */
  fetchIntercom: PropTypes.func,
  /**
   * Function to fetch sidebar
   */
  fetchSidebar: PropTypes.func.isRequired,
  /**
   * Data for the sidebar
   */
  sidebar: PropTypes.object.isRequired,
};

AppLayout.defaultProps = {
  children: null,
  fetchUser: null,
  user: null,
  intercom: null,
  languageLoading: false,
  fetchAccount: () => {},
  fetchIntercom: () => {},
};

export function mapStateToProps (state) {
  return {
    fullname: getFullName(state),
    user: getUser(state),
    intercom: getIntercom(state),
    languageLoading: state.i18n.loading,
    sidebar: state.admin.sidebar.data,
  };
}

export default connect(
  mapStateToProps,
  {
    fetchUser, fetchIntercom, fetchAccount, fetchLanguageStrings, fetchSidebar,
  },
  null,
  {
    context: ReduxContext,
  },
)(AppLayout);
