/**
 * Backup language strings in case the API call fails.
 * Eventually this'll be replaced with an error page
 */
export default {
  domain: null,
  'plural-forms': 'nplurals=2; plural=(n != 1);',
  messages: {
    '': {
      'BookingLive Connect': [
        'BookingLive Connect',
      ],
      'The :attribute must be accepted.': [
        'The :attribute must be accepted.',
      ],
      'The :attribute is not a valid URL.': [
        'The :attribute is not a valid URL.',
      ],
      'The :attribute must be a date after :date.': [
        'The :attribute must be a date after :date.',
      ],
      'The :attribute must be a date after or equal to :date.': [
        'The :attribute must be a date after or equal to :date.',
      ],
      'The :attribute may only contain letters.': [
        'The :attribute may only contain letters.',
      ],
      'The :attribute may only contain letters, numbers, and dashes.': [
        'The :attribute may only contain letters, numbers, and dashes.',
      ],
      'The :attribute may only contain letters and numbers.': [
        'The :attribute may only contain letters and numbers.',
      ],
      'The :attribute must be an array.': [
        'The :attribute must be an array.',
      ],
      'The :attribute must be a date before :date.': [
        'The :attribute must be a date before :date.',
      ],
      'The :attribute must be a date before or equal to :date.': [
        'The :attribute must be a date before or equal to :date.',
      ],
      'The :attribute must be between :min and :max.': [
        'The :attribute must be between :min and :max.',
      ],
      'The :attribute must be between :min and :max kilobytes.': [
        'The :attribute must be between :min and :max kilobytes.',
      ],
      'The :attribute must be between :min and :max characters.': [
        'The :attribute must be between :min and :max characters.',
      ],
      'The :attribute must have between :min and :max items.': [
        'The :attribute must have between :min and :max items.',
      ],
      'The :attribute field must be true or false.': [
        'The :attribute field must be true or false.',
      ],
      'The :attribute confirmation does not match.': [
        'The :attribute confirmation does not match.',
      ],
      'The :attribute is not a valid date.': [
        'The :attribute is not a valid date.',
      ],
      'The :attribute does not match the format :format.': [
        'The :attribute does not match the format :format.',
      ],
      'The :attribute and :other must be different.': [
        'The :attribute and :other must be different.',
      ],
      'The :attribute must be :digits digits.': [
        'The :attribute must be :digits digits.',
      ],
      'The :attribute must be between :min and :max digits.': [
        'The :attribute must be between :min and :max digits.',
      ],
      'The :attribute has invalid image dimensions.': [
        'The :attribute has invalid image dimensions.',
      ],
      'The :attribute field has a duplicate value.': [
        'The :attribute field has a duplicate value.',
      ],
      'The :attribute must be a valid email address.': [
        'The :attribute must be a valid email address.',
      ],
      'The selected :attribute is invalid.': [
        'The selected :attribute is invalid.',
      ],
      'The :attribute must be a file.': [
        'The :attribute must be a file.',
      ],
      'The :attribute field must have a value.': [
        'The :attribute field must have a value.',
      ],
      'The :attribute must be an image.': [
        'The :attribute must be an image.',
      ],
      'The :attribute field does not exist in :other.': [
        'The :attribute field does not exist in :other.',
      ],
      'The :attribute must be an integer.': [
        'The :attribute must be an integer.',
      ],
      'The :attribute must be a valid IP address.': [
        'The :attribute must be a valid IP address.',
      ],
      'The :attribute must be a valid IPv4 address.': [
        'The :attribute must be a valid IPv4 address.',
      ],
      'The :attribute must be a valid IPv6 address.': [
        'The :attribute must be a valid IPv6 address.',
      ],
      'The :attribute must be a valid JSON string.': [
        'The :attribute must be a valid JSON string.',
      ],
      'The :attribute may not be greater than :max.': [
        'The :attribute may not be greater than :max.',
      ],
      'The :attribute may not be greater than :max kilobytes.': [
        'The :attribute may not be greater than :max kilobytes.',
      ],
      'The :attribute may not be greater than :max characters.': [
        'The :attribute may not be greater than :max characters.',
      ],
      'The :attribute may not have more than :max items.': [
        'The :attribute may not have more than :max items.',
      ],
      'The :attribute must be a file of type: :values.': [
        'The :attribute must be a file of type: :values.',
      ],
      'The :attribute must be at least :min.': [
        'The :attribute must be at least :min.',
      ],
      'The :attribute must be at least :min kilobytes.': [
        'The :attribute must be at least :min kilobytes.',
      ],
      'The :attribute must be at least :min characters.': [
        'The :attribute must be at least :min characters.',
      ],
      'The :attribute must have at least :min items.': [
        'The :attribute must have at least :min items.',
      ],
      'The :attribute must be a number.': [
        'The :attribute must be a number.',
      ],
      'The :attribute field must be present.': [
        'The :attribute field must be present.',
      ],
      'The :attribute format is invalid.': [
        'The :attribute format is invalid.',
      ],
      'The :attribute field is required.': [
        'The :attribute field is required.',
      ],
      'The :attribute field is required when :other is :value.': [
        'The :attribute field is required when :other is :value.',
      ],
      'The :attribute field is required unless :other is in :values.': [
        'The :attribute field is required unless :other is in :values.',
      ],
      'The :attribute field is required when :values is present.': [
        'The :attribute field is required when :values is present.',
      ],
      'The :attribute field is required when :values is not present.': [
        'The :attribute field is required when :values is not present.',
      ],
      'The :attribute field is required when none of :values are present.': [
        'The :attribute field is required when none of :values are present.',
      ],
      'The :attribute and :other must match.': [
        'The :attribute and :other must match.',
      ],
      'The :attribute must be :size.': [
        'The :attribute must be :size.',
      ],
      'The :attribute must be :size kilobytes.': [
        'The :attribute must be :size kilobytes.',
      ],
      'The :attribute must be :size characters.': [
        'The :attribute must be :size characters.',
      ],
      'The :attribute must contain :size items.': [
        'The :attribute must contain :size items.',
      ],
      'The :attribute must be a string.': [
        'The :attribute must be a string.',
      ],
      'The :attribute must be a valid zone.': [
        'The :attribute must be a valid zone.',
      ],
      'The :attribute has already been taken.': [
        'The :attribute has already been taken.',
      ],
      'The :attribute failed to upload.': [
        'The :attribute failed to upload.',
      ],
      ':attribute is not a valid phone number': [
        ':attribute is not a valid phone number',
      ],
      ':attribute must be a date before the end date': [
        ':attribute must be a date before the end date',
      ],
      'Passwords must be at least six characters and match the confirmation.': [
        'Passwords must be at least six characters and match the confirmation.',
      ],
      'Your password has been reset!': [
        'Your password has been reset!',
      ],
      'We have e-mailed your password reset link!': [
        'We have e-mailed your password reset link!',
      ],
      'This password reset token is invalid.': [
        'This password reset token is invalid.',
      ],
      "We can't find a user with that e-mail address.": [
        "We can't find a user with that e-mail address.",
      ],
      '&laquo; Previous': [
        '&laquo; Previous',
      ],
      'Next &raquo;': [
        'Next &raquo;',
      ],
      'These credentials do not match our records.': [
        'These credentials do not match our records.',
      ],
      'The :attribute must be a PNG or JPEG image': [
        'The :attribute must be a PNG or JPEG image',
      ],
      'Too many login attempts. Please try again in :seconds seconds.': [
        'Too many login attempts. Please try again in :seconds seconds.',
      ],
      'The :attribute must be an image no larger that 1mb in size': [
        'The :attribute must be an image no larger that 1mb in size',
      ],
      'The :attribute must be a valid data url string.': [
        'The :attribute must be a valid data url string.',
      ],
      'Expected content type "application json" but got :attribute': [
        'Expected content type "application json" but got :attribute',
      ],
      'Set up your first item': [
        'Set up your first item',
      ],
      'Create your first bookable item.': [
        'Create your first bookable item.',
      ],
      'Get started': [
        'Get started',
      ],
      'Find out how': [
        'Find out how',
      ],
      'Configure Stripe payments': [
        'Configure Stripe payments',
      ],
      'Link your account to Stripe to enable card payments.': [
        'Link your account to Stripe to enable card payments.',
      ],
      'Configure Stripe': [
        'Configure Stripe',
      ],
      'Create your first upsell': [
        'Create your first upsell',
      ],
      'Add Upsell': [
        'Add Upsell',
      ],
      'Take your first order': [
        'Take your first order',
      ],
      'Take your first order!': [
        'Take your first order!',
      ],
      'Make Booking': [
        'Make Booking',
      ],
      'Take your first 10 orders': [
        'Take your first 10 orders',
      ],
      'Take your first 10 orders!': [
        'Take your first 10 orders!',
      ],
      'Take your first 100 orders!': [
        'Take your first 100 orders!',
      ],
      'Take your first 100 orders': [
        'Take your first 100 order',
      ],
      ':attribute is not valid with price_point_id': [
        ':attribute is not valid with price_point_id',
      ],
      ':attribute is not valid with upsell_id': [
        ':attribute is not valid with upsell_id',
      ],
      'You do not have access to this resource.': [
        'You do not have access to this resource.',
      ],
      'Either an item_id, upsell_id or resource_id must be specified.': [
        'Either an item_id, upsell_id or resource_id must be specified.',
      ],
      '<Link to="login">Log in<Link> to your account': [
        '<Link to="login">Log in</Link> to your account',
      ],
      'Log in to your account': [
        'Log in to your account',
      ],
      'Free Sign Up': [
        'Free Sign Up',
      ],
      'Error logging in': [
        'Error logging in',
      ],
      'Please confirm your username and password are correct, and your account has been activated.': [
        'Please confirm your username and password are correct, and your account has been activated.',
      ],
      'Badge for': [
        'Badge for',
      ],
      completed: [
        'completed',
      ],
      Close: [
        'Close',
      ],
      Achievements: [
        'Achievements',
      ],
      'Show not-started': [
        'Show not-started',
      ],
      'Show in-progress': [
        'Show in-progress',
      ],
      'Show completed': [
        'Show completed',
      ],
      'All the achievements are hidden! Use the filters above to show some.': [
        'All the achievements are hidden! Use the filters above to show some.',
      ],
      'Start Booking': [
        'Start Booking',
      ],
      Dashboard: [
        'Dashboard',
      ],
      Customers: [
        'Customers',
      ],
      Calendar: [
        'Calendar',
      ],
      Events: [
        'Events',
      ],
      Bookings: [
        'Bookings',
      ],
      Items: [
        'Items',
      ],
      Reports: [
        'Reports',
      ],
      Attendees: [
        'Attendees',
      ],
      Transactions: [
        'Transactions',
      ],
      Settings: [
        'Settings',
      ],
      'System Setup': [
        'System Setup',
      ],
      Resources: [
        'Resources',
      ],
      Upsells: [
        'Upsells',
      ],
      Staff: [
        'Staff',
      ],
      Places: [
        'Places',
      ],
      'About Connect': [
        'About Connect',
      ],
      Profile: [
        'Profile',
      ],
      Billing: [
        'Billing',
      ],
      'Sign out': [
        'Sign out',
      ],
      Event: [
        'Event',
      ],
      Item: [
        'Item',
      ],
      Start: [
        'Start',
      ],
      End: [
        'End',
      ],
      'Max Capacity': [
        'Max Capacity',
      ],
      'Available Capacity': [
        'Available Capacity',
      ],
      Order: [
        'Order',
      ],
      Reference: [
        'Reference',
      ],
      Date: [
        'Date',
      ],
      Total: [
        'Total',
      ],
      Name: [
        'Name',
      ],
      Email: [
        'Email',
      ],
      'View Booking': [
        'View Booking',
      ],
      'Customers Calendar': [
        'Customers Calendar',
      ],
      'Events Calendar': [
        'Events Calendar',
      ],
      'Back to Calendar': [
        'Back to Calendar',
      ],
      'Limit to Bookable Items': [
        'Limit to Bookable Items',
      ],
      Title: [
        'Title',
      ],
      'First Name': [
        'First Name',
      ],
      'Last Name': [
        'Last Name',
      ],
      Save: [
        'Save',
      ],
      'You need to provide a first name': [
        'You need to provide a first name',
      ],
      'You need to provide a surname': [
        'You need to provide a surname',
      ],
      'Edit Attendee': [
        'Edit Attendee',
      ],
      'Attendee ID': [
        'Attendee ID',
      ],
      Created: [
        'Created',
      ],
      Edit: [
        'Edit',
      ],
      'Back to attendees': [
        'Back to attendees',
      ],
      Cost: [
        'Cost',
      ],
      Status: [
        'Status',
      ],
      Attendee: [
        'Attendee',
      ],
      Attendances: [
        'Attendances',
      ],
      'Sign Up': [
        'Sign Up',
      ],
      Login: [
        'Login',
      ],
      'Enterprise Solutions': [
        'Enterprise Solutions',
      ],
      'Email Address': [
        'Email Address',
      ],
      Password: [
        'Password',
      ],
      'Forgot your password?': [
        'Forgot your password?',
      ],
      'Email is required': [
        'Email is required',
      ],
      'Password is required': [
        'Password is required',
      ],
      'Confirm Password': [
        'Confirm Password',
      ],
      'Reset Password': [
        'Reset Password',
      ],
      'First a little about you': [
        'First a little about you',
      ],
      Telephone: [
        'Telephone',
      ],
      'Must be at least 6 characters': [
        'Must be at least 6 characters',
      ],
      'I have read and agree to the <a href="https://www.bookinglive.com/standard-terms" target="_blank" rel="noopener noreferrer">Terms and Conditions</a>': [
        'I have read and agree to the <a href="https://www.bookinglive.com/standard-terms" target="_blank" rel="noopener noreferrer">Terms and Conditions</a>',
      ],
      'Get Started': [
        'Get Started',
      ],
      'First name is required': [
        'First name is required',
      ],
      'Last name is required': [
        'Last name is required',
      ],
      'Telephone number is required': [
        'Telephone number is required',
      ],
      'Email is not valid': [
        'Email is not valid',
      ],
      'Password is too short': [
        'Password is too short',
      ],
      'You must agree to our terms and conditions': [
        'You must agree to our terms and conditions',
      ],
      'Adventurous Activities': [
        'Adventurous Activities',
      ],
      Tours: [
        'Tours',
      ],
      Classes: [
        'Classes',
      ],
      Experiences: [
        'Experiences',
      ],
      'Retail Omni Channel': [
        'Retail Omni Channel',
      ],
      Other: [
        'Other',
      ],
      "Now let's set up your system": [
        "Now let's set up your system",
      ],
      'Company Name': [
        'Company Name',
      ],
      Industry: [
        'Industry',
      ],
      URL: [
        'URL',
      ],
      "This is the link you'll give to customers to make a booking. Keep it short and choose carefully, it can't be changed later.": [
        "This is the link you'll give to customers to make a booking. Keep it short and choose carefully, it can't be changed later.",
      ],
      'System Language': [
        'System Language',
      ],
      'You can change this later': [
        'You can change this later',
      ],
      'Error during activation': [
        'Error during activation',
      ],
      'Almost there...': [
        'Almost there...',
      ],
      'Before we activate your account': [
        'Before we activate your account',
      ],
      'Would you like us to create some sample data to get you started?': [
        'Would you like us to create some sample data to get you started?',
      ],
      "We'll create a product, two upsells, and some orders. You're welcome to edit these for your own use, or ignore them and create new ones": [
        "We'll create a product, two upsells, and some orders. You're welcome to edit these for your own use, or ignore them and create new ones",
      ],
      'Continue Without Sample Data': [
        'Continue Without Sample Data',
      ],
      'Continue With Sample Data': [
        'Continue With Sample Data',
      ],
      "Sorry! We couldn't activate the sample data": [
        "Sorry! We couldn't activate the sample data",
      ],
      Continue: [
        'Continue',
      ],
      'Page not found': [
        'Page not found',
      ],
      "We can't seem to find the page you're looking for.": [
        "We can't seem to find the page you're looking for.",
      ],
      'Error code: 404': [
        'Error code: 404',
      ],
      "<strong>If you followed a link to get here:</strong> it's probably broken, please message us and we'll fix it.": [
        "<strong>If you followed a link to get here:</strong> it's probably broken, please message us and we'll fix it.",
      ],
      '<strong>If you typed the address:</strong> please check you typed it correctly.': [
        '<strong>If you typed the address:</strong> please check you typed it correctly.',
      ],
      'In the meantime, here are some links to get you back on track': [
        'In the meantime, here are some links to get you back on track',
      ],
      'Help Centre': [
        'Help Centre',
      ],
      'Reset your password': [
        'Reset your password',
      ],
      'Resetting your password': [
        'Resetting your password',
      ],
      'If the email address you have specified is registered, we will have sent you an email with a link to reset your password.': [
        'If the email address you have specified is registered, we will have sent you an email with a link to reset your password.',
      ],
      'Registration Successful': [
        'Registration Successful',
      ],
      'A verification link has been emailed to you.': [
        'A verification link has been emailed to you.',
      ],
      'Please click on that link to verify your account.': [
        'Please click on that link to verify your account.',
      ],
      'Register your Connect account': [
        'Register your Connect account',
      ],
      'Log in': [
        'Log in',
      ],
      'Basket Items': [
        'Basket Items',
      ],
      'NO ITEMS IN BASKET': [
        'NO ITEMS IN BASKET',
      ],
      Extras: [
        'Extras',
      ],
      'TOTAL TO PAY': [
        'TOTAL TO PAY',
      ],
      Basket: [
        'Basket',
      ],
      item: [
        'item',
      ],
      items: [
        'items',
      ],
      'Stripe Configuration': [
        'Stripe Configuration',
      ],
      'Book Now': [
        'Book Now',
      ],
      Book: [
        'Book',
      ],
      'Step {completed.filter(Boolean).length + 1} of 5': [
        'Step {completed.filter(Boolean).length + 1} of 5',
      ],
      Payment: [
        'Payment',
      ],
      'View Confirmation': [
        'View Confirmation',
      ],
      'Loading terms and conditions': [
        'Loading terms and conditions',
      ],
      'By continuing you agree to the following': [
        'By continuing you agree to the following',
      ],
      'Terms & Conditions': [
        'Terms & Conditions',
      ],
      "Sorry! There's no availability for this date.": [
        "Sorry! There's no availability for this date.",
      ],
      'Please choose another.': [
        'Please choose another.',
      ],
      'Start Time': [
        'Start Time',
      ],
      'End Time': [
        'End Time',
      ],
      Price: [
        'Price',
      ],
      Spaces: [
        'Spaces',
      ],
      Action: [
        'Action',
      ],
      Disabled: [
        'Disabled',
      ],
      Deselect: [
        'Deselect',
      ],
      Select: [
        'Select',
      ],
      Loading: [
        'Loading',
      ],
      'Specify Payment': [
        'Specify Payment',
      ],
      each: [
        'each',
      ],
      'Add for': [
        'Add for',
      ],
      'Select sessions for': [
        'Select sessions for',
      ],
      Mr: [
        'Mr',
      ],
      Mrs: [
        'Mrs',
      ],
      Miss: [
        'Miss',
      ],
      Ms: [
        'Ms',
      ],
      Dr: [
        'Dr',
      ],
      'Customer Details': [
        'Customer Details',
      ],
      'You need to provide a last name': [
        'You need to provide a last name',
      ],
      'You need to provide a valid email': [
        'You need to provide a valid email',
      ],
      'You need to provide a valid telephone number': [
        'You need to provide a valid telephone number',
      ],
      'At least one time slot must be entered': [
        'At least one time slot must be entered',
      ],
      'The purchaser can only be selected as one participant': [
        'The purchaser can only be selected as one participant',
      ],
      'You must select at least one attendee price point': [
        'You must select at least one attendee price point',
      ],
      'Must be a number': [
        'Must be a number',
      ],
      'Must be at least :number': [
        'Must be at least :number',
      ],
      'Enter Quantity': [
        'Enter Quantity',
      ],
      List: [
        'List',
      ],
      'Configuration Issue': [
        'Configuration Issue',
      ],
      "This account hasn't been configured to take payments yet.": [
        "This account hasn't been configured to take payments yet.",
      ],
      "<strong>If you're the administrator:</strong> please connect your system to Stripe via the billing page.": [
        "<strong>If you're the administrator:</strong> please connect your system to Stripe via the billing page.",
      ],
      "<strong>If you're trying to make a booking:</strong> you won't be able to continue until the system is fully configured. Please contact the system administrator.": [
        "<strong>If you're trying to make a booking:</strong> you won't be able to continue until the system is fully configured. Please contact the system administrator.",
      ],
      'Warning: Your account has not been connected to Stripe': [
        'Warning: Your account has not been connected to Stripe',
      ],
      'You will not be able to make card bookings until <Link to="/billing">billing has been configured</Link>. As an administrator you will still be able to make cash bookings but the <strong>public facing booking process has been disabled.</strong>': [
        'You will not be able to make card bookings until <Link to="/billing">billing has been configured</Link>. As an administrator you will still be able to make cash bookings but the <strong>public facing booking process has been disabled.</strong>',
      ],
      'Add Attendees': [
        'Add Attendees',
      ],
      'Please Select Attendees': [
        'Please Select Attendees',
      ],
      'Select Sessions': [
        'Select Sessions',
      ],
      'Please specify number of attendees': [
        'Please specify number of attendees',
      ],
      'Order not found': [
        'Order not found',
      ],
      'Error: The order could not be found.': [
        'Error: The order could not be found.',
      ],
      Error: [
        'Error',
      ],
      'There has been an error displaying the summary, please contact us to confirm your order.': [
        'There has been an error displaying the summary, please contact us to confirm your order.',
      ],
      'Booking Summary': [
        'Booking Summary',
      ],
      'A confirmation email has been sent to {order.purchaser.email}.': [
        'A confirmation email has been sent to {order.purchaser.email}.',
      ],
      Summary: [
        'Summary',
      ],
      'Booking reference': [
        'Booking reference',
      ],
      'Purchase date': [
        'Purchase date',
      ],
      'Total amount': [
        'Total amount',
      ],
      'Booking status': [
        'Booking status',
      ],
      'Full name': [
        'Full name',
      ],
      'Email address': [
        'Email address',
      ],
      'Contact number': [
        'Contact number',
      ],
      'Order Items': [
        'Order Items',
      ],
      'Select Customer': [
        'Select Customer',
      ],
      'Add a New Customer': [
        'Add a New Customer',
      ],
      'There are currently no previous purchasers.': [
        'There are currently no previous purchasers.',
      ],
      'Select the new customer action on the right hand side.': [
        'Select the new customer action on the right hand side.',
      ],
      'Select Optional Extras': [
        'Select Optional Extras',
      ],
      'New Attendee': [
        'New Attendee',
      ],
      'As Above': [
        'As Above',
      ],
      'See schedule and book sessions': [
        'See schedule and book sessions',
      ],
      'Sign up for emails (optional)': [
        'Sign up for emails (optional)',
      ],
      "We'd love to keep you up to date with occasional news, please tick the box below if you'd like to hear from us. You can change these settings at any time by contacting us.": [
        "We'd love to keep you up to date with occasional news, please tick the box below if you'd like to hear from us. You can change these settings at any time by contacting us.",
      ],
      "Yes please, I'd like to receive communications by email": [
        "Yes please, I'd like to receive communications by email",
      ],
      'Attendee Details': [
        'Attendee Details',
      ],
      'Order amount': [
        'Order amount',
      ],
      'Order status': [
        'Order status',
      ],
      'Select an item': [
        'Select an item',
      ],
      'Powered by': [
        'Powered by',
      ],
      'No payment required': [
        'No payment required',
      ],
      'Error: No outstanding payment amount detected for order.': [
        'Error: No outstanding payment amount detected for order.',
      ],
      'System not configured': [
        'System not configured',
      ],
      'Error: The system has not been configured to take payments': [
        'Error: The system has not been configured to take payments',
      ],
      Delete: [
        'Delete',
      ],
      View: [
        'View',
      ],
      "You don't have any items": [
        "You don't have any items",
      ],
      'Add item': [
        'Add item',
      ],
      'Add staff': [
        'Add staff',
      ],
      'Add upsell': [
        'Add upsell',
      ],
      Published: [
        'Published',
      ],
      Submit: [
        'Submit',
      ],
      Remove: [
        'Remove',
      ],
      Add: [
        'Add',
      ],
      Label: [
        'Label',
      ],
      Address: [
        'Address',
      ],
      Text: [
        'Text',
      ],
      Textarea: [
        'Textarea',
      ],
      Radio: [
        'Radio',
      ],
      Checkbox: [
        'Checkbox',
      ],
      Type: [
        'Type',
      ],
      Value: [
        'Value',
      ],
      Required: [
        'Required',
      ],
      'Filter by Reference, Name or Email': [
        'Filter by Reference, Name or Email',
      ],
      'Edit Customer': [
        'Edit Customer',
      ],
      'Customer ID': [
        'Customer ID',
      ],
      Income: [
        'Income',
      ],
      Booking: [
        'Booking',
      ],
      'Back to customers': [
        'Back to customers',
      ],
      'Getting Started': [
        'Getting Started',
      ],
      'New here? Here are some tasks to get you started.': [
        'New here? Here are some tasks to get you started.',
      ],
      'View all :achievements achievements': [
        'View all :achievements achievements',
      ],
      'Hints & Tips': [
        'Hints & Tips',
      ],
      Next: [
        'Next',
      ],
      'Congratulations! Your account has been successfully activated': [
        'Congratulations! Your account has been successfully activated',
      ],
      'Now you can start configuring your system to take bookings.': [
        'Now you can start configuring your system to take bookings.',
      ],
      'Stuck on something? Contact us via live chat': [
        'Stuck on something? Contact us via live chat',
      ],
      'The attendees report shows a complete list of attendees': [
        'The attendees report shows a complete list of attendees',
      ],
      'Change your Terms and Conditions through Account Settings': [
        'Change your Terms and Conditions through Account Settings',
      ],
      'Edit your personal details on your profile page': [
        'Edit your personal details on your profile page',
      ],
      'Code snippets to embed the booking process into your website can be found in the "Linking to and sharing your item" section when viewing an item': [
        'Code snippets to embed the booking process into your website can be found in the "Linking to and sharing your item" section when viewing an item',
      ],
      'Connect with Stripe on the billing page': [
        'Connect with Stripe on the billing page',
      ],
      'You can see all of a days booked events on the customers calendar': [
        'You can see all of a days booked events on the customers calendar',
      ],
      'Complete the Getting Started tasks below to finish setup': [
        'Complete the Getting Started tasks below to finish setup',
      ],
      'Set up a staff member from the staff page, then you can assign them to bookings': [
        'Set up a staff member from the staff page, then you can assign them to bookings',
      ],
      "We've created some quick videos to help you set up your Connect system": [
        "We've created some quick videos to help you set up your Connect system",
      ],
      'Visit the Help Center for system documentation': [
        'Visit the Help Center for system documentation',
      ],
      'Change Columns': [
        'Change Columns',
      ],
      'Download as CSV': [
        'Download as CSV',
      ],
      "Sorry, we can't show this": [
        "Sorry, we can't show this",
      ],
      "Either it doesn't exist or there was an error in retrieving it.": [
        "Either it doesn't exist or there was an error in retrieving it.",
      ],
      'Error code': [
        'Error code',
      ],
      'The server returned an error message:': [
        'The server returned an error message:',
      ],
      'If you keep seeing this page, please send us a message.': [
        'If you keep seeing this page, please send us a message.',
      ],
      'This component failed to load.': [
        'This component failed to load.',
      ],
      'Keep seeing this?': [
        'Keep seeing this?',
      ],
      'Visit the Help Centre': [
        'Visit the Help Centre',
      ],
      'Are you sure you want to leave this page? Changes you have made may not have been saved': [
        'Are you sure you want to leave this page? Changes you have made may not have been saved',
      ],
      'Leave page?': [
        'Leave page?',
      ],
      'Stay here': [
        'Stay here',
      ],
      'Leave Page': [
        'Leave Page',
      ],
      hide: [
        'hide',
      ],
      show: [
        'show',
      ],
      'Drop an image here or click to upload': [
        'Drop an image here or click to upload',
      ],
      'Can be jpeg or png. Maximum size 1mb.': [
        'Can be jpeg or png. Maximum size 1mb.',
      ],
      'Preview of the image you uploaded': [
        'Preview of the image you uploaded',
      ],
      'Remove image': [
        'Remove image',
      ],
      'Image Preview': [
        'Image Preview',
      ],
      Monday: [
        'Monday',
      ],
      Tuesday: [
        'Tuesday',
      ],
      Wednesday: [
        'Wednesday',
      ],
      Thursday: [
        'Thursday',
      ],
      Friday: [
        'Friday',
      ],
      Saturday: [
        'Saturday',
      ],
      Sunday: [
        'Sunday',
      ],
      'Days it applies to': [
        'Days it applies to',
      ],
      Copy: [
        'Copy',
      ],
      Copied: [
        'Copied',
      ],
      'Start Date': [
        'Start Date',
      ],
      'End Date': [
        'End Date',
      ],
      Capacity: [
        'Capacity',
      ],
      Days: [
        'Days',
      ],
      'Delete Timeslot': [
        'Delete Timeslot',
      ],
      'Are you sure you want to delete this timeslot?': [
        'Are you sure you want to delete this timeslot?',
      ],
      Selected: [
        'Selected',
      ],
      'No spaces': [
        'No spaces',
      ],
      'No place is needed for this item.': [
        'No place is needed for this item.',
      ],
      Cancel: [
        'Cancel',
      ],
      'This item is a virtual event.': [
        'This item is a virtual event.',
      ],
      Details: [
        'Details',
      ],
      "You don't have any places set up yet.": [
        "You don't have any places set up yet.",
      ],
      'This item takes place in an existing place.': [
        'This item takes place in an existing place.',
      ],
      'Start Date (YYYY-MM-DD)': [
        'Start Date (YYYY-MM-DD)',
      ],
      'End Date (YYYY-MM-DD)': [
        'End Date (YYYY-MM-DD)',
      ],
      'You need at least one timeslot': [
        'You need at least one timeslot',
      ],
      'You need to provide an item start time': [
        'You need to provide an item start time',
      ],
      'You need to provide an item end time': [
        'You need to provide an item end time',
      ],
      'End time cannot be before start time': [
        'End time cannot be before start time',
      ],
      'Start time cannot be after end time': [
        'Start time cannot be after end time',
      ],
      'You need to provide an item start date': [
        'You need to provide an item start date',
      ],
      'You need to provide an item end date': [
        'You need to provide an item end date',
      ],
      'End date cannot be before start date': [
        'End date cannot be before start date',
      ],
      'Start date cannot be after end date': [
        'Start date cannot be after end date',
      ],
      'You need to specify the days of the week that this item occurs': [
        'You need to specify the days of the week that this item occurs',
      ],
      'Price per item': [
        'Price per item',
      ],
      'Price Type': [
        'Price Type',
      ],
      'e.g.': [
        'e.g.',
      ],
      'e.g Adult, Child, OAP etc.': [
        'e.g Adult, Child, OAP etc.',
      ],
      'Add Price Point': [
        'Add Price Point',
      ],
      Description: [
        'Description',
      ],
      'Item name': [
        'Item name',
      ],
      Image: [
        'Image',
      ],
      Video: [
        'Video',
      ],
      'Must be a YouTube video': [
        'Must be a YouTube video',
      ],
      'Additional Information': [
        'Additional Information',
      ],
      'Where is this item booking taking place?': [
        'Where is this item booking taking place?',
      ],
      "It's a Virtual Event": [
        "It's a Virtual Event",
      ],
      'In An Existing Place': [
        'In An Existing Place',
      ],
      "There's No Place": [
        "There's No Place",
      ],
      'Who is taking the item bookings on site?': [
        'Who is taking the item bookings on site?',
      ],
      'Staff Members': [
        'Staff Members',
      ],
      'Save Item': [
        'Save Item',
      ],
      Media: [
        'Media',
      ],
      "You don't have any items yet.": [
        "You don't have any items yet.",
      ],
      "Let's add your first.": [
        "Let's add your first.",
      ],
      'Edit Event Information': [
        'Edit Event Information',
      ],
      'Linking to and sharing your item': [
        'Linking to and sharing your item',
      ],
      'Direct link': [
        'Direct link',
      ],
      'Share this link with customers to send them directly to the booking page for this item.': [
        'Share this link with customers to send them directly to the booking page for this item.',
      ],
      'Iframe Embed': [
        'Iframe Embed',
      ],
      'You can use this HTML code to embed the item on your own website.': [
        'You can use this HTML code to embed the item on your own website.',
      ],
      'Embedding an Item': [
        'Embedding an Item',
      ],
      Availability: [
        'Availability',
      ],
      'See All Availability': [
        'See All Availability',
      ],
      'Add Availability': [
        'Add Availability',
      ],
      'Return to item dashboard': [
        'Return to item dashboard',
      ],
      'Edit Availability': [
        'Edit Availability',
      ],
      'Create Availability': [
        'Create Availability',
      ],
      Upsell: [
        'Upsell',
      ],
      Customer: [
        'Customer',
      ],
      'Back to Bookings': [
        'Back to Bookings',
      ],
      processing: [
        'processing',
      ],
      pending: [
        'pending',
      ],
      refunded: [
        'refunded',
      ],
      cancelled: [
        'cancelled',
      ],
      failed: [
        'failed',
      ],
      'Search by Reference or Customer': [
        'Search by Reference or Customer',
      ],
      'Advanced Filters': [
        'Advanced Filters',
      ],
      'Limit to status': [
        'Limit to status',
      ],
      'Limit to Order Dates Between': [
        'Limit to Order Dates Between',
      ],
      'Limit to Event Dates Between': [
        'Limit to Event Dates Between',
      ],
      'Order ID': [
        'Order ID',
      ],
      Amount: [
        'Amount',
      ],
      'Payment Method': [
        'Payment Method',
      ],
      'Print Booking': [
        'Print Booking',
      ],
      'Cancel Booking': [
        'Cancel Booking',
      ],
      'Booking Detail': [
        'Booking Detail',
      ],
      'Confirm Cancellation': [
        'Confirm Cancellation',
      ],
      'Are you sure you want to cancel this booking?': [
        'Are you sure you want to cancel this booking?',
      ],
      stripe: [
        'stripe',
      ],
      pdq: [
        'pdq',
      ],
      cash: [
        'cash',
      ],
      cheque: [
        'cheque',
      ],
      'free-event': [
        'free-event',
      ],
      'Post Code': [
        'Post Code',
      ],
      Pay: [
        'Pay',
      ],
      'Card number': [
        'Card number',
      ],
      'Expiration date': [
        'Expiration date',
      ],
      CVC: [
        'CVC',
      ],
      'By continuing you agree that we can process your data in line with our <a href="https://www.bookinglive.com/standard-terms/" target="_blank" rel="noopener noreferrer">Terms & Conditions</a> and <a href="https://www.bookinglive.com/privacy-policy/" target="_blank" rel="noopener noreferrer">Privacy Policy</a>.': [
        'By continuing you agree that we can process your data in line with our <a href="https://www.bookinglive.com/standard-terms/" target="_blank" rel="noopener noreferrer">Terms & Conditions</a> and <a href="https://www.bookinglive.com/privacy-policy/" target="_blank" rel="noopener noreferrer">Privacy Policy</a>.',
      ],
      'Payment error': [
        'Payment Error',
      ],
      'No payment to be taken now': [
        'No payment to be taken now',
      ],
      Card: [
        'Card',
      ],
      'Payment Details': [
        'Payment Details',
      ],
      'How is the customer paying?': [
        'How is the customer paying?',
      ],
      'Select payment method': [
        'Select payment method',
      ],
      'Check this box to confirm that the customer agrees to the terms and conditions': [
        'Check this box to confirm that the customer agrees to the terms and conditions',
      ],
      'Confirm Booking': [
        'Confirm Booking',
      ],
      'You need to select a payment method': [
        'You need to select a payment method',
      ],
      'You need to agree to the terms and conditions': [
        'You need to agree to the terms and conditions',
      ],
      'Make Payment': [
        'Make Payment',
      ],
      'New password must be at least 6 characters': [
        'New password must be at least 6 characters',
      ],
      'Password confirmation is required': [
        'Password confirmation is required',
      ],
      'Password confirmation must match the new password': [
        'Password confirmation must match the new password',
      ],
      Company: [
        'Company',
      ],
      Position: [
        'Position',
      ],
      'Address 1': [
        'Address 1',
      ],
      'Address 2': [
        'Address 2',
      ],
      Town: [
        'Town',
      ],
      Country: [
        'Country',
      ],
      'You need to enter a postcode': [
        'You need to enter a postcode',
      ],
      'You need to enter a town': [
        'You need to enter a town',
      ],
      'You need to select a country': [
        'You need to select a country',
      ],
      'Current Password': [
        'Current Password',
      ],
      'Edit Profile': [
        'Edit Profile',
      ],
      'Change Password': [
        'Change Password',
      ],
      'Listing your items': [
        'Listing your items',
      ],
      'All of your items are publicly listed on the items listing, you can share or embed this page with these code snippets. To share an individual item, you can find the code snippets on the item page.': [
        'All of your items are publicly listed on the items listing, you can share or embed this page with these code snippets. To share an individual item, you can find the code snippets on the item page.',
      ],
      'Share this link with customers to send them directly to the items listing.': [
        'Share this link with customers to send them directly to the items listing.',
      ],
      'You can use this HTML code to embed the page on your own website.': [
        'You can use this HTML code to embed the page on your own website.',
      ],
      'Error changing password': [
        'Error changing password',
      ],
      'Please ensure your current password is correct and the new passwords match': [
        'Please ensure your current password is correct and the new passwords match',
      ],
      'Update Password': [
        'Update Password',
      ],
      'Return to Profile': [
        'Return to Profile',
      ],
      'Order Reference': [
        'Order Reference',
      ],
      Purchaser: [
        'Purchaser',
      ],
      'Search Reference, Name, Email': [
        'Search Reference, Name, Email',
      ],
      'Attendee Reference': [
        'Attendee Reference',
      ],
      'Transaction Date': [
        'Transaction Date',
      ],
      'Transaction Type': [
        'Transaction Type',
      ],
      'Gross Value': [
        'Gross Value',
      ],
      'The total amount the customer was charged': [
        'The total amount the customer was charged',
      ],
      Fees: [
        'Fees',
      ],
      "BookingLive's fee for the transaction": [
        "BookingLive's fee for the transaction",
      ],
      'Net Value': [
        'Net Value',
      ],
      'The value after all fees are deducted': [
        'The value after all fees are deducted',
      ],
      'Events Start': [
        'Events Start',
      ],
      'Start date for the first event of this transaction': [
        'Start date for the first event of this transaction',
      ],
      'Event Start Time': [
        'Event Start Time',
      ],
      'End date for the last event of this transaction': [
        'End date for the last event of this transaction',
      ],
      'Events End': [
        'Events End',
      ],
      'Events End Time': [
        'Events End Time',
      ],
      'Transaction Start Date': [
        'Transaction Start Date',
      ],
      'Transaction End Date': [
        'Transaction End Date',
      ],
      'Event Start Date': [
        'Event Start Date',
      ],
      'Event End Date': [
        'Event End Date',
      ],
      'Number Available': [
        'Number Available',
      ],
      'You need to provide a name.': [
        'You need to provide a name.',
      ],
      'You need to provide a description.': [
        'You need to provide a description.',
      ],
      'You need to specify the number available.': [
        'You need to specify the number available.',
      ],
      'Manage Kit': [
        'Manage Kit',
      ],
      "You don't have any kits yet.": [
        "You don't have any kits yet.",
      ],
      County: [
        'County',
      ],
      'Country Code': [
        'Country Code',
      ],
      'You need to provide a name': [
        'You need to provide a name',
      ],
      'You need to provide a address': [
        'You need to provide a address',
      ],
      'You need to provide a town': [
        'You need to provide a town',
      ],
      'You need to provide a postcode': [
        'You need to provide a postcode',
      ],
      'You need to provide a country id': [
        'You need to provide a country id',
      ],
      Number: [
        'Number',
      ],
      'Manage Place': [
        'Manage Place',
      ],
      'Manage Space': [
        'Manage Space',
      ],
      'Add Space': [
        'Add Space',
      ],
      "You don't have any spaces here yet.": [
        "You don't have any spaces here yet.",
      ],
      'Add Place': [
        'Add Place',
      ],
      'Manage Places': [
        'Manage Places',
      ],
      "You don't have any places yet.": [
        "You don't have any places yet.",
      ],
      'Already have an account?': [
        'Already have an account?',
      ],
      Biography: [
        'Biography',
      ],
      'You need to provide a position.': [
        'You need to provide a position.',
      ],
      'You need to provide an email address.': [
        'You need to provide an email address.',
      ],
      'Invalid email address': [
        'Invalid email address',
      ],
      'You need to provide a telephone number.': [
        'You need to provide a telephone number.',
      ],
      'At least one link must be entered': [
        'At least one link must be entered',
      ],
      'You need to provide a link type': [
        'You need to provide a link type',
      ],
      'You need to provide a link': [
        'You need to provide a link',
      ],
      'Manage Staff': [
        'Manage Staff',
      ],
      "You don't have any staff yet": [
        "You don't have any staff yet",
      ],
      'Limitless (e.g insurance, early pickup)': [
        'Limitless (e.g insurance, early pickup)',
      ],
      'Limited physical item (kit)': [
        'Limited physical item (kit)',
      ],
      'Per booking': [
        'Per booking',
      ],
      'Per event / schedule': [
        'Per event / schedule',
      ],
      'Per day': [
        'Per day',
      ],
      'Per hour': [
        'Per hour',
      ],
      'Type of Upsell': [
        'Type of Upsell',
      ],
      'Select type of upsell': [
        'Select type of upsell',
      ],
      Kit: [
        'Kit',
      ],
      'Select Kit': [
        'Select Kit',
      ],
      'Applies to the following items': [
        'Applies to the following items',
      ],
      'Select items': [
        'Select items',
      ],
      'Default Price': [
        'Default Price',
      ],
      'Select price type': [
        'Select price type',
      ],
      'You need to provide an upsell type.': [
        'You need to provide an upsell type.',
      ],
      'You need to assign some items.': [
        'You need to assign some items.',
      ],
      'You need to provide a default price.': [
        'You need to provide a default price.',
      ],
      'Manage Upsell': [
        'Manage Upsell',
      ],
      'Manage Upsells': [
        'Manage Upsells',
      ],
      "You don't have any upsells yet.": [
        "You don't have any upsells yet.",
      ],
      'Link to Terms and Conditions': [
        'Link to Terms and Conditions',
      ],
      'Type of Terms and conditions': [
        'Type of Terms and conditions',
      ],
      'You need to provide a company name': [
        'You need to provide a company name',
      ],
      'You need to provide a valid url to your terms': [
        'You need to provide a valid url to your terms',
      ],
      'You need to provide your booking terms and conditions': [
        'You need to provide your booking terms and conditions',
      ],
      'Edit Account': [
        'Edit Account',
      ],
      Language: [
        'Language',
      ],
      'Edit Account Settings': [
        'Edit Account Settings',
      ],
      Support: [
        'Support',
      ],
      'Stuck?': [
        'Stuck?',
      ],
      'Try searching the Help Center for answers and system documentation. You can also speak to us through Live Chat.': [
        'Try searching the Help Center for answers and system documentation. You can also speak to us through Live Chat.',
      ],
      Legal: [
        'Legal',
      ],
      'Privacy Policy': [
        'Privacy Policy',
      ],
      'Search the Help Center': [
        'Search the Help Center',
      ],
      Search: [
        'Search',
      ],
      'Select a time': [
        'Select a time',
      ],
      space: [
        'space',
      ],
      spaces: [
        'spaces',
      ],
    },
  },
};
